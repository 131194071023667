import React, { useState, useEffect } from "react";
import { db } from "./firebase-config.js";
import { collection, getDocs, addDoc, query, where, serverTimestamp } from "firebase/firestore";
import './styles.css';

export default function BookingForm() {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    checkIn: "",
    checkOut: "",
    adults: 1,
    children: 0,
    roomNumber: "",
    roomType: "",
    status: "Occupied",
    price: "",
    paymentMethod: "",
    message: "",
    numberOfPeople: 1,
    roomCount: 1,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];
  
  // Calculate maximum date (e.g., 1 year from today)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const maxDateStr = maxDate.toISOString().split('T')[0];

  useEffect(() => {
    const fetchAvailableRooms = async () => {
      setLoading(true);
      setError(null);
      console.log("Attempting to fetch rooms...");

      try {
        // Create a query for available rooms
        const roomsRef = collection(db, "rooms");
        const roomsQuery = query(roomsRef, where("status", "!=", "Occupied"));
        
        const snapshot = await getDocs(roomsQuery);
        
        if (snapshot.empty) {
          console.log("No available rooms found");
          setAvailableRooms([]);
        } else {
          const rooms = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("Fetched rooms:", rooms);
          setAvailableRooms(rooms);
        }
      } catch (err) {
        console.error("Error fetching available rooms:", err);
        setError("Failed to fetch available rooms. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableRooms();
  }, []);

  useEffect(() => {
    // Validate checkout date whenever check-in date changes
    if (formData.checkIn && formData.checkOut) {
      if (new Date(formData.checkOut) < new Date(formData.checkIn)) {
        setFormData(prev => ({
          ...prev,
          checkOut: formData.checkIn
        }));
      }
    }
  }, [formData.checkIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      numberOfPeople: name === "adults" || name === "children" 
        ? parseInt(name === "adults" ? value : prevData.adults) + parseInt(name === "children" ? value : prevData.children)
        : prevData.numberOfPeople
    }));
  };

  const handleRoomChange = (e) => {
    const selectedRoom = availableRooms.find((room) => room.roomNumber === e.target.value);
    if (selectedRoom) {
      setFormData(prev => ({
        ...prev,
        roomNumber: selectedRoom.roomNumber,
        roomType: selectedRoom.type,
        price: selectedRoom.price,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.roomNumber) {
      alert("Please select a room to continue.");
      return;
    }

    try {
      const reservationsRef = collection(db, "reservations");
      
      // Create the new reservation
      const newReservation = {
        ...formData,
        timestamp: serverTimestamp(),
        createdAt: serverTimestamp(),
        numberOfPeople: parseInt(formData.adults) + parseInt(formData.children),
        roomCount: parseInt(formData.roomCount),
        status: "Pending" // Initial status
      };

      const docRef = await addDoc(reservationsRef, newReservation);
      
      // Update the reservation with its ID
      await addDoc(reservationsRef, {
        ...newReservation,
        id: docRef.id,
      });

      alert("Reservation successful!");
      setFormData(initialFormData);
    } catch (err) {
      console.error("Error creating reservation:", err);
      alert(`Failed to create reservation: ${err.message}`);
    }
  };

  if (loading) {
    return (
      <div className="booking-form">
        <div className="loading">Loading available rooms...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="booking-form">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="booking-form">
      <h2>Room Reservation</h2>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-floating">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label>Full Name</label>
          </div>

          <div className="form-floating">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Email Address</label>
          </div>

          <div className="form-floating">
            <input
              type="tel"
              name="phone"
              className="form-control"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label>Phone Number</label>
          </div>

          <div className="form-floating">
            <input
              type="date"
              name="checkIn"
              className="form-control"
              value={formData.checkIn}
              onChange={handleChange}
              min={today}
              max={maxDateStr}
              required
            />
            <label>Check-In Date</label>
          </div>

          <div className="form-floating">
            <input
              type="date"
              name="checkOut"
              className="form-control"
              value={formData.checkOut}
              onChange={handleChange}
              min={formData.checkIn || today}
              max={maxDateStr}
              required
            />
            <label>Check-Out Date</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              name="adults"
              className="form-control"
              placeholder="Number of Adults"
              value={formData.adults}
              onChange={handleChange}
              min="1"
              required
            />
            <label>Adults</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              name="children"
              className="form-control"
              placeholder="Number of Children"
              value={formData.children}
              onChange={handleChange}
              min="0"
              required
            />
            <label>Children</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              name="roomCount"
              className="form-control"
              placeholder="Number of Rooms"
              value={formData.roomCount}
              onChange={handleChange}
              min="1"
              required
            />
            <label>Number of Rooms</label>
          </div>

          <div className="form-floating">
            <select
              name="roomNumber"
              className="form-select"
              value={formData.roomNumber}
              onChange={handleRoomChange}
              required
            >
              <option value="" disabled>
                {availableRooms.length === 0 
                  ? "No rooms available" 
                  : "Select a Room"
                }
              </option>
              {availableRooms.map((room) => (
                <option key={room.id} value={room.roomNumber}>
                  {`${room.roomNumber} - ${room.type} - K${room.price}`}
                </option>
              ))}
            </select>
            <label>Room</label>
          </div>

          <div className="form-floating">
            <select
              name="paymentMethod"
              className="form-select"
              value={formData.paymentMethod}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="mobile money">Mobile Money</option>
              <option value="Credit Card">Credit Card</option>
            </select>
            <label>Payment Method</label>
          </div>

          <div className="col-span-full text-center">
            <button type="submit" className="btn btn-primary">
              Confirm Reservation
            </button>
          </div>
        </div>
      </form>

      <style>{`
        .loading {
          text-align: center;
          padding: 20px;
          font-size: 18px;
          color: #666;
        }

        .error-message {
          background-color: #ffebee;
          color: #c62828;
          padding: 15px;
          border-radius: 4px;
          margin-bottom: 20px;
          text-align: center;
        }
      `}</style>
    </div>
  );
}