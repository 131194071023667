import React from "react";
import { Link } from "react-router-dom";
import Heading from "../common/Heading";
import { services } from "../data/Data";

export default function Services({ isHomePage = false }) {
  const handleClick = (id, path) => {
    // IDs where clicking is disabled
    const disabledIds = [3, 7, 8, 9, 10];
    if (disabledIds.includes(id)) {
      alert("To be added soon");
    } else {
      // Redirect for other services
      window.location.href = `/services/${path}`;
    }
  };

  return (
    <>
      {!isHomePage && (
        <section className="contact-img">
          <Heading
            heading="Our Services"
            title="Services"
            subtitle="Explore Our"
          />
        </section>
      )}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {services.map((item, index) => (
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
                key={index}
              >
                <div
                  className="service-item rounded"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => handleClick(item.id, item.path)}
                >
                  <div className="service-icon bg-transparent border rounded p-1">
                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                      <span>{item.icon}</span>
                    </div>
                  </div>
                  <h5 className="mb-3">{item.name}</h5>
                  <p className="text-body mb-0">{item.discription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
