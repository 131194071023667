import React, { useState, useEffect } from "react";
import { db } from "./firebase-config.js";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";

export default function BookingSystem() {
  const [activeTab, setActiveTab] = useState("room");
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState([]);

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    paymentMethod: "",
    status: "Occupied",
    message: "",
    venueId: "",
    venueName: "",
    bookingDate: "",
    numberOfPeople: "",
    totalPrice: 0,
    roomId: "",
    roomNumber: "",
    roomType: "",
    checkIn: "",
    checkOut: "",
    adults: 1,
    children: 0,
    price: "",
    reservationDate: "",
    reservationType: "",
    guestCount: "",
    selectedRoom: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [venues, setVenues] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Verify Firebase connection
        if (!db) {
          throw new Error("Firebase database connection not established");
        }

        // Fetch rooms with error handling
        try {
          const roomsCollection = collection(db, "rooms");
          const roomsSnapshot = await getDocs(roomsCollection);
          
          if (roomsSnapshot.empty) {
            console.log("No rooms found in database");
          }
          
          setRooms(
            roomsSnapshot.docs.map(doc => ({
              id: doc.id,
              roomType: doc.data().type || "Unknown Type",
              roomNumber: doc.data().roomNumber || "Unknown Number",
              price: doc.data().price || "0",
            }))
          );
        } catch (roomError) {
          console.error("Error fetching rooms:", roomError);
          throw new Error(`Failed to fetch rooms: ${roomError.message}`);
        }

        // Fetch venues with error handling
        try {
          const venuesCollection = collection(db, "venues");
          const venuesSnapshot = await getDocs(venuesCollection);
          
          if (venuesSnapshot.empty) {
            console.log("No venues found in database");
          }
          
          setVenues(
            venuesSnapshot.docs.map(doc => ({
              id: doc.id,
              venue: doc.data().venue || "Unknown Venue",
              price: doc.data().price || "0",
            }))
          );
        } catch (venueError) {
          console.error("Error fetching venues:", venueError);
          throw new Error(`Failed to fetch venues: ${venueError.message}`);
        }

      } catch (err) {
        console.error("Error in data fetching:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVenueChange = e => {
    const selectedVenue = venues.find(venue => venue.id === e.target.value);
    if (selectedVenue) {
      setFormData(prev => ({
        ...prev,
        venueId: selectedVenue.id,
        venueName: selectedVenue.venue,
        totalPrice: parseFloat(selectedVenue.price) || 0,
      }));
    }
  };

  const handleRoomChange = e => {
    const selectedRoom = rooms.find(room => room.id === e.target.value);
    if (selectedRoom) {
      setFormData(prev => ({
        ...prev,
        roomId: selectedRoom.id,
        roomNumber: selectedRoom.roomNumber,
        roomType: selectedRoom.roomType,
        totalPrice: parseFloat(selectedRoom.price) || 0,
        selectedRoom: selectedRoom.roomNumber,
      }));
    }
  };

  const handleReservationRoomChange = e => {
    const selectedRoom = rooms.find(room => room.id === e.target.value);
    if (selectedRoom) {
      setFormData(prev => ({
        ...prev,
        roomId: selectedRoom.id,
        roomNumber: selectedRoom.roomNumber,
        roomType: selectedRoom.roomType,
        selectedRoom: selectedRoom.roomNumber,
      }));
    }
  };

  const handleReservationSubmit = async e => {
    e.preventDefault();
    setError(null);
    try {
      const newReservationData = {
        ...formData,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, "reservations"), newReservationData);
      alert("Reservation booking successful!");
      setFormData(initialFormData);
    } catch (err) {
      console.error("Error creating reservation:", err);
      setError("Failed to create reservation. Please try again.");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    try {
      const status = activeTab === "room" ? "Occupied" : "Pending";
      
      const newData = {
        ...formData,
        status: status,
        createdAt: serverTimestamp(),
        price: formData.price || 0,
        totalPrice: formData.totalPrice || 0,
      };
  
      switch (activeTab) {
        case "venue":
          await addDoc(collection(db, "venueBookings"), newData);
          break;
        case "room":
          await addDoc(collection(db, "bookings"), newData);
          break;
        case "reservation":
          await handleReservationSubmit(e);
          return; // Return here to avoid double processing
      }
  
      alert(`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} booking successful!`);
      setFormData(initialFormData);
    } catch (err) {
      console.error("Error creating booking:", err);
      setError("Failed to create booking. Please try again.");
    }
  };

  const renderErrorMessage = () => {
    if (error) {
      return (
        <div style={{ 
          color: 'red', 
          padding: '10px', 
          margin: '10px 0', 
          border: '1px solid red',
          borderRadius: '5px'
        }}>
          Error: {error}
        </div>
      );
    }
    return null;
  };

  const renderLoadingMessage = () => {
    if (loading) {
      return (
        <div style={{
          padding: '10px',
          margin: '10px 0',
          textAlign: 'center'
        }}>
          Loading data...
        </div>
      );
    }
    return null;
  };

  return (
    <div className="booking-system">
      <h2 className="booking-title">Professional Booking System</h2>
      
      {renderErrorMessage()}
      {renderLoadingMessage()}
      
        <div className="booking-tabs">
        <button
        className={`tab-button ${activeTab === "room" ? "active" : ""}`}
        onClick={() => setActiveTab("room")}
        >
        Room Booking
        </button>
        <button
        className={`tab-button ${activeTab === "reservation" ? "active" : ""}`}
        onClick={() => setActiveTab("reservation")}
        >
        Reservation Booking
        </button>
        <button
        className={`tab-button ${activeTab === "venue" ? "active" : ""}`}
        onClick={() => setActiveTab("venue")}
        >
        Venue Booking
        </button>
        </div>

      {!loading && (
        <div className="booking-form">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-floating">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <label>Full Name</label>
              </div>

              <div className="form-floating">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label>Email Address</label>
              </div>

              <div className="form-floating">
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <label>Phone Number</label>
              </div>

              {activeTab === "venue" && (
                <>
                  <div className="form-floating">
                    <select
                      name="venueId"
                      className="form-select"
                      value={formData.venueId}
                      onChange={handleVenueChange}
                      required
                    >
                      <option value="">Select Venue</option>
                      {venues.map(venue => (
                        <option key={venue.id} value={venue.id}>
                          {venue.venue} - K{venue.price}
                        </option>
                      ))}
                    </select>
                    <label>Venue</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="date"
                      name="bookingDate"
                      className="form-control"
                      value={formData.bookingDate}
                      onChange={handleChange}
                      min={new Date().toISOString().split("T")[0]}
                      required
                    />
                    <label>Booking Date</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="number"
                      name="numberOfPeople"
                      className="form-control"
                      placeholder="Number of People"
                      value={formData.numberOfPeople}
                      onChange={handleChange}
                      required
                      min="1"
                    />
                    <label>Number of People</label>
                  </div>
                </>
              )}

              {activeTab === "room" && (
                <>
                  <div className="form-floating">
                    <select
                      name="roomId"
                      className="form-select"
                      value={formData.roomId}
                      onChange={handleRoomChange}
                      required
                    >
                      <option value="">Select Room</option>
                      {rooms.map(room => (
                        <option key={room.id} value={room.id}>
                          {room.roomNumber} - {room.roomType} - K{room.price}
                        </option>
                      ))}
                    </select>
                    <label>Room</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="date"
                      name="checkIn"
                      className="form-control"
                      value={formData.checkIn}
                      onChange={handleChange}
                      required
                    />
                    <label>Check-In Date</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="date"
                      name="checkOut"
                      className="form-control"
                      value={formData.checkOut}
                      onChange={handleChange}
                      required
                    />
                    <label>Check-Out Date</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="number"
                      name="adults"
                      className="form-control"
                      placeholder="Number of Adults"
                      value={formData.adults}
                      onChange={handleChange}
                      required
                      min="1"
                    />
                    <label>Number of Adults</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="number"
                      name="children"
                      className="form-control"
                      placeholder="Number of Children"
                      value={formData.children}
                      onChange={handleChange}
                      min="0"
                    />
                    <label>Number of Children</label>
                  </div>
                </>
              )}

              {activeTab === "reservation" && (
                <>
                  <div className="form-floating">
                    <select
                      name="roomId"
                      className="form-select"
                      value={formData.roomId}
                      onChange={handleReservationRoomChange}
                      required
                    >
                      <option value="">Select Room for Reservation</option>
                      {rooms.map(room => (
                        <option key={room.id} value={room.id}>
                          {room.roomNumber} - {room.roomType} - K{room.price}
                        </option>
                      ))}
                    </select>
                    <label>Room for Reservation</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="date"
                      name="checkIn"
                      className="form-control"
                      value={formData.checkIn}
                      onChange={handleChange}
                      required
                    />
                    <label>Reservation Check-In Date</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="date"
                      name="checkOut"
                      className="form-control"
                      value={formData.checkOut}
                      onChange={handleChange}
                      required
                    />
                    <label>Reservation Check-Out Date</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="number"
                      name="adults"
                      className="form-control"
                      placeholder="Number of Adults"
                      value={formData.adults}
                      onChange={handleChange}
                      required
                      min="1"
                    />
                    <label>Number of Adults</label>
                  </div>

                  <div className="form-floating">
                    <input
                      type="number"
                      name="children"
                      className="form-control"
                      placeholder="Number of Children"
                      value={formData.children}
                      onChange={handleChange}
                      min="0"
                    />
                    <label>Number of Children</label>
                  </div>
                </>
              )}

              <div className="form-floating">
                <select
                  name="paymentMethod"
                  className="form-select"
                  value={formData.paymentMethod}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Mobile Money">Mobile Money</option>
                  <option value="Credit Card">Credit Card</option>
                </select><label>Payment Method</label>
              </div>

              <div className="form-floating">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Message (Optional)"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                />
                <label>Message (Optional)</label>
              </div>

              <div className="form-group">
                <button type="submit" className="btn">Submit</button>
              </div>
            </div>
          </form>
        </div>
      )}

      <style jsx>{`
        .booking-title {
          text-align: center;
          font-size: 2rem;
          margin-bottom: 20px;
        }
        .tab-button {
          padding: 15px;
          background-color: rgb(255, 153, 51);
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          text-decoration: none;
          margin-right: 10px;
          border-radius: 5px;
          color: white;
        }
        .tab-button.active {
          background-color: rgb(255, 115, 0);
          text-decoration: underline;
        }
        .booking-tabs {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        .form-floating {
          position: relative;
          margin-bottom: 1rem;
        }
        .form-floating label {
          position: absolute;
          left: 10px;
          top: 5px;
          font-size: 0.875rem;
          color: #666;
        }
        .form-control,
        .form-select {
          width: 100%;
          padding: 1.5rem 0.75rem 0.5rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: white;
        }
        .form-control:focus,
        .form-select:focus {
          outline: none;
          border-color: rgb(255, 115, 0);
          box-shadow: 0 0 0 2px rgba(255, 115, 0, 0.2);
        }
        .form-group {
          display: flex;
          justify-content: center;
          margin-top: 1.5rem;
        }
        .btn {
          background-color: rgb(255, 115, 0);
          color: white;
          padding: 12px 24px;
          font-size: 1.1rem;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.2s;
        }
        .btn:hover {
          background-color: rgb(230, 103, 0);
        }
        .grid {
          display: grid;
          gap: 1rem;
        }
        @media (min-width: 768px) {
          .grid-cols-2 {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      `}</style>
    </div>
  );
}