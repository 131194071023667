import React, { useState, useEffect } from "react";
import { db } from "./firebase-config.js";
import { collection, getDocs, doc, setDoc, serverTimestamp, query, where, getDoc } from "firebase/firestore";

export default function BookingPopup({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    roomId: "",
    roomNumber: "",
    name: "",
    email: "",
    phone: "",
    checkIn: "",
    checkOut: "",
    adults: 1,
    children: 0,
    rooms: "",
    roomType: "",
    status: "Occupied",
    price: "",
    paymentMethod: "",
    message: "",
    numberOfPeople: "",
    guestCount: "",
    reservationDate: "",
    reservationType: "",
    venueId: "",
    venueName: "",
  });

  const [availableRooms, setAvailableRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAvailableRooms = async () => {
    setLoading(true);
    setError(null);
    try {
      const roomsQuery = query(collection(db, "rooms"), where("status", "==", "Available"));
      const querySnapshot = await getDocs(roomsQuery);
      const rooms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAvailableRooms(rooms);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchAvailableRooms();
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRoomChange = (e) => {
    const selectedRoom = availableRooms.find((room) => room.roomNumber === e.target.value);
    if (selectedRoom) {
      setFormData((prevData) => ({
        ...prevData,
        roomId: selectedRoom.id,
        roomType: selectedRoom.type,
        price: selectedRoom.price,
        roomNumber: selectedRoom.roomNumber,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.roomId || !formData.roomNumber) {
      alert("Please select a room to continue.");
      return;
    }

    try {
      const roomRef = doc(db, "rooms", formData.roomId);
      const roomSnapshot = await getDoc(roomRef);

      if (roomSnapshot.exists() && roomSnapshot.data().status === "Occupied") {
        alert("This room has just been booked. Please select another room.");
        await fetchAvailableRooms();
        return;
      }

      const newBooking = {
        ...formData,
        timestamp: serverTimestamp(),
        createdAt: serverTimestamp(),
        bookingDate: serverTimestamp(),
        numberOfPeople: String(formData.adults + formData.children),
        guestCount: String(formData.adults + formData.children),
      };

      const bookingRef = doc(collection(db, "bookings"));
      await setDoc(bookingRef, {
        ...newBooking,
        id: bookingRef.id,
        roomId: formData.roomId,
      });

      await setDoc(roomRef, {
        status: "Occupied",
        currentBooking: {
          bookingId: bookingRef.id,
          checkIn: formData.checkIn,
          checkOut: formData.checkOut,
          createdAt: serverTimestamp(),
          guestName: formData.name,
          phone: formData.phone,
        },
        lastUpdated: serverTimestamp(),
      }, { merge: true });

      alert("Booking successful!");
      onClose();
    } catch (err) {
      console.error("Error creating booking: ", err);
      alert(`Failed to create booking: ${err.message}`);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`popup ${isOpen ? 'show' : ''}`}>
      <div className="popup-content">
        <h2>Room Booking</h2>
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        {loading ? (
          <div className="loading">Loading available rooms...</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-floating">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <label>Full Name</label>
              </div>

              <div className="form-floating">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label>Email Address</label>
              </div>

              <div className="form-floating">
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <label>Phone Number</label>
              </div>

              <div className="form-floating">
                <input
                  type="date"
                  name="checkIn"
                  className="form-control"
                  value={formData.checkIn}
                  onChange={handleChange}
                  min={new Date().toISOString().split("T")[0]}
                  required
                />
                <label>Check-in Date</label>
              </div>

              <div className="form-floating">
                <input
                  type="date"
                  name="checkOut"
                  className="form-control"
                  value={formData.checkOut}
                  onChange={handleChange}
                  min={formData.checkIn}
                  required
                />
                <label>Check-out Date</label>
              </div>

              <div className="form-floating">
                <input
                  type="number"
                  name="adults"
                  className="form-control"
                  placeholder="Number of Adults"
                  value={formData.adults}
                  onChange={handleChange}
                  min="1"
                  required
                />
                <label>Adults</label>
              </div>

              <div className="form-floating">
                <input
                  type="number"
                  name="children"
                  className="form-control"
                  placeholder="Number of Children"
                  value={formData.children}
                  onChange={handleChange}
                  min="0"
                  required
                />
                <label>Children</label>
              </div>

              <div className="form-floating">
                <select
                  name="rooms"
                  className="form-select"
                  value={formData.roomNumber}
                  onChange={handleRoomChange}
                  required
                >
                  <option value="" disabled>
                    {availableRooms.length === 0 
                      ? "No rooms available" 
                      : "Select a Room"
                    }
                  </option>
                  {availableRooms.map((room) => (
                    <option key={room.id} value={room.roomNumber}>
                      {`${room.roomNumber} - ${room.type} - K${room.price}`}
                    </option>
                  ))}
                </select>
                <label>Room</label>
              </div>

              <div className="form-floating">
                <select
                  name="paymentMethod"
                  className="form-select"
                  value={formData.paymentMethod}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select Payment Method</option>
                  <option value="Cash">cash</option>
                  <option value="Mobile Money">Mobile Money</option>
                  <option value="Credit Card">Credit Card</option>
                </select>
                <label>Payment Method</label>
              </div>

              <div className="col-span-full text-center">
                <button type="submit" className="btn btn-primary">
                  Confirm Booking
                </button>
              </div>
            </div>
          </form>
        )}
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </div>
      <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        .popup.show {
          visibility: visible;
          opacity: 1;
        }

        .popup-content {
          background-color: #fff;
          border-radius: 8px;
          padding: 20px;
          width: 100%;
          max-width: 600px;
          max-height: 90vh;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          position: relative;
          overflow-y: auto;
        }

        .popup-content h2 {
          text-align: center;
          margin-bottom: 20px;
          font-size: 24px;
          color: #333;
        }

        .error-message {
          background-color: #ffebee;
          color: #c62828;
          padding: 10px;
          border-radius: 4px;
          margin-bottom: 15px;
          text-align: center;
        }

        .loading {
          text-align: center;
          padding: 20px;
          color: #666;
        }

        .btn-secondary {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 8px 16px;
          background-color: #f44336;
          color: white;
          border: none;
          border-radius: 50%;
          cursor: pointer;
        }

        .btn-secondary:hover {
          background-color: #d32f2f;
        }

        .form-floating {
          position: relative;
          margin-bottom: 15px;
        }

        .form-floating input,
        .form-floating select {
          width: 100%;
          padding: 10px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 16px;
          color: #333;
          transition: border-color 0.3s ease;
          height: 48px;
        }

        .form-floating input:focus,
        .form-floating select:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
        }

        .form-floating label {
          position: absolute;
          top: -10px;
          left: 10px;
          font-size: 14px;
          color: #777;
          background-color: #fff;
          padding: 0 4px;
          pointer-events: none;
        }

        .form-floating input:focus + label,
        .form-floating select:focus + label {
          color: #007bff;
        }

        .btn {
          display: inline-block;
          padding: 12px 24px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          text-align: center;
          transition: background-color 0.3s ease;
        }

        .btn:hover {
          background-color:hsl(19, 90.50%, 50.60%);
        }

        .btn:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }

        .grid {
          display: grid;
          gap: 20px;
        }

        .grid-cols-1 {
          grid-template-columns: 1fr;
        }

        .md\\:grid-cols-2 {
          grid-template-columns: repeat(2, 1fr);
        }

        .col-span-full {
          grid-column: 1 / -1;
        }

        @media (max-width: 768px) {
          .popup-content {
            margin: 20px;
            max-height: calc(100vh - 40px);
          }

          .grid {
            grid-template-columns: 1fr;
          }

          .form-floating input,
          .form-floating select {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
}