import React, { useState } from "react";
import { db } from "./firebase-config";
import { collection, addDoc } from "firebase/firestore";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setStatus("Please enter a valid email.");
      return;
    }

    try {
      // Add email to Firestore
      await addDoc(collection(db, "newsletterSubscriptions"), { email });

      // Show success message
      alert("Thank you for subscribing to our newsletter!");

      // Clear the input field after submission
      setEmail("");
      setStatus("");
    } catch (error) {
      // Handle errors (e.g., Firestore issues)
      alert("Error subscribing. Please try again.");
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <div className="container newsletter mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="row justify-content-center">
          <div className="col-lg-10 border rounded p-1">
            <div className="border rounded text-center p-1">
              <div className="bg-white rounded text-center p-5">
                <h4 className="mb-4">
                  Subscribe Our{" "}
                  <span className="text-uppercase" style={{ color: "#E25217" }}>
                    Newsletter
                  </span>
                </h4>
                <form onSubmit={handleSubmit}>
                  <div className="position-relative mx-auto" style={{ maxWidth: "400px" }}>
                    <input
                      className="form-control w-100 py-3 ps-4 pe-5"
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleChange}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {status && <p className="mt-3 text-danger">{status}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
