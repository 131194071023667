import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { services } from '../components/data/Data';
import CommonHeading from '../components/common/CommonHeading';
import Heading from '../components/common/Heading';
import Slider from 'react-slick';

const ServiceDetailPage = () => {
  const { servicePath } = useParams();
  const navigate = useNavigate();

  const service = services.find((s) => s.path === servicePath);

  if (!service) {
    return (
      <div className="container py-5">
        <div className="text-center">
          <h2 className="text-danger">Service Not Found</h2>
          <button 
            className="btn btn-primary mt-3" 
            onClick={() => navigate('/services')}
          >
            Back to Services
          </button>
        </div>
      </div>
    );
  }

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <Heading title="Services" />
      <CommonHeading title={service.name} />

      <div className="container-xxl py-5">
        <div className="container">
          {/* Image Slider Section */}
          {service.images && service.images.length > 0 && (
            <div className="mb-5 custom-slider">
              <Slider {...sliderSettings}>
                {service.images.map((img, index) => (
                  <div key={index} className="slider-item px-2">
                    <img 
                      src={img} 
                      alt={`${service.name} ${index + 1}`} 
                      className="img-fluid rounded"
                      style={{
                        height: '400px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {/* Description Sections in Row Layout */}
          <div className="row g-4">
            {/* Overview Section */}
            <div className="col-md-4">
              <div className="bg-white rounded p-4 h-100 shadow-sm wow fadeInUp" data-wow-delay="0.1s">
                <h4 className="text-primary mb-3">Overview</h4>
                <p className="mb-0">{service.description}</p>
              </div>
            </div>

            {/* Features Section */}
            <div className="col-md-4">
              <div className="bg-white rounded p-4 h-100 shadow-sm wow fadeInUp" data-wow-delay="0.2s">
                <h4 className="text-primary mb-3">Features & Services</h4>
                <div className="ms-2">
                  {service.fullDescription.split('\n').map((item, index) => (
                    item.trim() && (
                      <div key={index} className="mb-2">
                        {item.startsWith('-') ? (
                          <p className="mb-2">
                            <i className="fa fa-check text-primary me-2"></i>
                            {item.substring(1).trim()}
                          </p>
                        ) : (
                          <p className="fw-bold mb-2">{item}</p>
                        )}
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>

            {/* Call to Action Section */}
            <div className="col-md-4">
              <div className="bg-white rounded p-4 h-100 shadow-sm wow fadeInUp" data-wow-delay="0.3s">
                <h4 className="text-primary mb-3">Ready to Book?</h4>
                <p className="mb-4">Contact us or make a reservation now</p>
                <div className="d-flex flex-column gap-3">
                  <button 
                    className="btn btn-primary py-3 px-4"
                    onClick={() => navigate('/contact')}
                  >
                    Contact Us
                  </button>
                  {(service.id === 1 || service.id === 4 || service.id === 5) && (
                    <button 
                      className="btn btn-dark py-3 px-4"
                      onClick={() => navigate('/booking')}
                    >
                      Book Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailPage;
